import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {
  tempState = [];
  breadcrumbs: Array<Object>;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.breadcrumbs = [];
    const url = 'lala';
    const status = true;
    const icon = false;
    const caption = false;
    this.breadcrumbs.push({
      label: 'lala',
      icon: icon,
      caption: caption,
      status: status,
      url: url
    });
  }
}
