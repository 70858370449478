import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment';


@Injectable()
export class AppService {

    private loading = new BehaviorSubject<boolean>(false);
    loadingState = this.loading.asObservable();

    url = environment.url;
    public headerWithBasic = new HttpHeaders({ 'Authorization' : 'Basic V2VDb3ZlcjpGYWN1SXNHYXlzaGFu' });
    public headerWithBearer;

    constructor(private http: HttpClient) { }
    setBearerToken(token){
        this.headerWithBearer = new HttpHeaders().set('Authorization' , 'Bearer ' + token);
    }
    isLogedIn() {
        if (localStorage.getItem('wecover_back_access_token') !== null) {
            if (localStorage.getItem('wecover_back_access_token') !== undefined) {
                return 1;
            }
        }
        return 0;
    }
    getUsuarioFromStorage() {
        if (localStorage.getItem('wecover_back_access_token') !== null) {
            if (localStorage.getItem('wecover_back_access_token') !== undefined) {
                return JSON.parse(localStorage.getItem('wecover_back_access_token'));
            }
        }
        return null;
    }
    isUsuarioSuperAdmin() {
        if (this.getUsuarioFromStorage()) {
            return this.getUsuarioFromStorage().tipoUsuario === 'ADMINISTRADOR';
        }
        return null;
    }
    login(userName: string, password: string) {
        const userId = localStorage.getItem('userId');
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        return this.http.post(this.url + 'WSUsuarios/Login', JSON.stringify({ usuario: userName, password: password, oneSignalToken: userId }), { headers: headers }).map(x => {
            let response = x;
            // let json = JSON.stringify({ nombre: response.nombre, apellido: response.apellido, mail: response.mail, userName: response.usuario, tipoUsuario: response.tipoUsuario, activo: response.activo });
            localStorage.setItem('/access_token', JSON.stringify(response));
        });
    }

    getheaderWithBearer() {
      return new HttpHeaders({ 
          'Authorization' : ('Bearer ' + localStorage.getItem('wecover_back_access_token')).toString(),
          "Accept": "application/json",
          //"Content-Type": "application/json"
        });
    }
    getheaderWithBearerCsv() {
        return new HttpHeaders({ 
            'Authorization' : ('Bearer ' + localStorage.getItem('wecover_back_access_token')).toString(),
            'Accept': 'text/csv',
            'Content-Type': 'text/csv',
          });
      }
    driveHeader(size) {
        return new HttpHeaders({ 
            'Content-Type': 'blob',
            'Content-Length': size,
            'Authorization' : ('Bearer ' + 'ya29.Il-vB2iWr2DLLNusUQ58qlgEuLRmQ_Q4Wyo9hTV6qgqYvWao9tT8RyOJipdNEc_1vWeTmuEYIFAQYf_gczIG-Lbknb9Q8pyDgyuOfvecaorivse9IRBHmtHa2PnSyUbC7Q')
        });
      }
    getHeadersWithBearerForPosts(){
        return new HttpHeaders({ 
            'Authorization' : ('Bearer ' + localStorage.getItem('wecover_back_access_token')).toString(),
            "Accept": "application/json"
            
          });
        //.set('Authorization' , ('Bearer ' + localStorage.getItem('wecover_back_access_token')).toString());
    }
    

    showLoading() {
      this.loading.next(true);
    }
    dismissLoading() {
      this.loading.next(false);
    }

}
