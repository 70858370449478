import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { MainComponent } from './pestanias/main/main.component';
import { AuthGuard } from './guard/auth.guard';
import { RoleAdminGuard } from './guard/role-admin.guard';
import { RoleSuperAdminGuard } from './guard/role-super-admin.guard';
import { AppService } from './services/app.service';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AuthComponent,
    BreadcrumbsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule
  ],
  providers: [MenuItems, AuthGuard, RoleAdminGuard, RoleSuperAdminGuard, AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
