import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class RoleSuperAdminGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('wecover_back_authorities_list') != null && localStorage.getItem('wecover_back_authorities_list').indexOf('ROLE_SUPER_ADMIN') > -1) {
      return true;
    } else {
      this.router.navigate(['/admin/reclamos']);
      return false;
    }
  }
}
