import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pestanias/main/main.component';
import { AuthGuard } from './guard/auth.guard';
import { RoleAdminGuard } from './guard/role-admin.guard';
import {AuthComponent} from './layout/auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard, RoleAdminGuard],
    children: [
      {
        path: '',
        redirectTo: 'admin/reclamos',
        pathMatch: 'full'
      },
      {
        path: 'user',
        loadChildren: './theme/user/user.module#UserModule'
      },
      {
        path: 'admin',
        loadChildren: './pestanias/pestanias.module#PestaniasModule'
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './theme/auth/auth.module#AuthModule'
      }
    ]
  },
  { path: '**' , redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
